import { PropsWithChildren, useCallback, useState } from 'react';
import { BreadcrumbState, UIContext } from '@ev/eva-container-api';

const notImplemented = () => {
  throw new Error('Not implemented');
};

export function UiContextProvider({ children }: PropsWithChildren) {
  const [breadcrumb, setBreadcrumb] = useState<BreadcrumbState>({
    current: '',
    breadcrumbs: [],
  });
  const clearBreadcrumb = useCallback(() => setBreadcrumb({ current: '', breadcrumbs: [] }), []);

  return (
    <UIContext.Provider
      value={{
        breadcrumb,
        setBreadcrumb,
        clearBreadcrumb,
        openPopovers: [],
        setOpenPopovers: notImplemented,
        setDrawerOpen: notImplemented,
        getIsDrawerOpen: notImplemented,
        isAnyDrawerOpen: false,
        isLayoutDeactivated: false,
        deactivateLayout: notImplemented,
        desktopTopBarHeight: 0,
        mobileTopBarHeight: 0,
      }}
    >
      {children}
    </UIContext.Provider>
  );
}
